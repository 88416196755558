/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Loading from '../assets/loading.svg';
import SuccessIcon from '../assets/success.png';
import axios from 'axios';
import { createClient } from '@supabase/supabase-js';

function Email({ setSubmitted }: any) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [buttonChange, setButtonChange] = useState('0');
  const supabase = createClient(
    'https://imkatxddcuwqpwudystr.supabase.co',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imlta2F0eGRkY3V3cXB3dWR5c3RyIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzM1MjYyNDksImV4cCI6MTk4OTEwMjI0OX0.REZTVRMfhfXIMaycA-7BhLyCnn43yjCCMfLWwl6nlNE',
  );

  async function handleSubmit(e) {
    e.preventDefault();
    setButtonChange('1');
    try {
      const { data, error } = await supabase
        .from('riff-snap')
        .insert({ name: name, email: email })
        .select();
      if (data) {
        setButtonChange('2');
        setTimeout(() => {
          setSubmitted(false);
        }, 2000);
        console.log(setSubmitted);
      } else {
        setButtonChange('3');
        setTimeout(() => {
          setButtonChange('0');
          setName('');
          setEmail('');
        }, 2000);
        console.log(error);
      }
    } catch (error) {
      setButtonChange('3');
      setTimeout(() => {
        setButtonChange('0');
        setName('');
        setEmail('');
      }, 2000);
      console.log(error);
    }
  }

  // console.log(data);
  // console.log(error);
  // axios
  //   .post('https://getform.io/f/9a310b1c-45f3-4c4a-a694-0858d754cda0', {
  //     name: name,
  //     email: email,
  //   })
  //   .then((response) => {
  //     setButtonChange('2');
  //     setTimeout(() => {
  //       setSubmitted(false);
  //     }, 2000);
  //     console.log(setSubmitted);
  //   })
  //   .catch((error) => {
  //     setButtonChange('3');
  //     setTimeout(() => {
  //       setButtonChange('0');
  //       setName('');
  //       setEmail('');
  //     }, 2000);
  //     console.log(error);
  //   });

  return (
    <>
      {buttonChange === '0' ? (
        <form
          onSubmit={handleSubmit}
          style={{ display: 'grid', padding: '1.5rem', width: '30%' }}
        >
          <input
            type="text"
            name="name"
            placeholder="Name"
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={{
              padding: '1.5rem',
              marginBottom: '1rem',
              borderRadius: '8px',
            }}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Enter your email address"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            style={{
              padding: '1.5rem',
              marginBottom: '1rem',
              borderRadius: '8px',
            }}
            required
          />

          <button type="submit">Send</button>
        </form>
      ) : buttonChange === '1' ? (
        <div style={{ marginTop: '2rem' }}>
          <img src={Loading} alt="Loading" />
        </div>
      ) : buttonChange === '2' ? (
        <div style={{ margin: '3rem', textAlign: 'center' }}>
          <img src={SuccessIcon} alt="Loading" style={{ width: '6rem' }} />
          <p style={{ fontSize: '2.4rem' }}>Successfully Register </p>
        </div>
      ) : buttonChange === '3' ? (
        <>Failed</>
      ) : (
        <></>
      )}
    </>
  );
}

export default Email;
